




































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { FormHelper } from '@/mixins'
import { Game } from '@/api'
import { GameModule } from '@/store/modules'

@Component({
  components: {
    Pagination: () => import('@/components/Pagination.vue'),
  }
})
export default class RbbRanking extends Mixins(FormHelper) {
  @GameModule.State characters
  @Prop() data

  activeRaid: Nullable<any> = null
  currentRaidData: Nullable<any> = null
  raidTypeData = []
  raidTypeDataFiltered = []
  fields = [
    { key: 'rank' },
    { key: 'name', label: 'Character Name' },
    { key: 'class' },
    { key: 'level' },
    { key: 'heroLevel' },
    { key: 'value', label: 'raids done' },
  ]
  raids = [
    { id: 0, displayName: 'Mother Cuby', name: 'Cuby', iconId: 1127 },
    { id: 1, displayName: 'Ginseng', name: 'Ginseng', iconId: 1128 },
    { id: 2, displayName: 'Castra', name: 'Castra', iconId: 1129 },
    { id: 3, displayName: 'Giant Black Spider', name: 'GiantBlackSpider',  iconId: 1130 },
    { id: 4, displayName: 'Massive Slade', name: 'Slade', iconId: 1131 },
    { id: 5, displayName: 'Chicken King', name: 'ChickenKing', iconId: 1195 },
    { id: 6, displayName: 'Namaju', name: 'Namaju', iconId: 1226 },
    { id: 8, displayName: 'Huge Snowman', name: 'Snowman', iconId: 1371 },
    { id: 9, displayName: 'Robber Gang', name: 'RobberGang', iconId: 2483 },
    { id: 10, displayName: 'Jack O\'Lantern', name: 'JackOLantern', iconId: 1915 },
    { id: 11, displayName: 'Chicken Queen', name: 'ChickenQueen', iconId: 4087 },
    { id: 12, displayName: 'Pete O\'Peng', name: 'Pirate', iconId: 1440 },
    { id: 13, displayName: 'Kertos The Demon Dog', name: 'Kertos', iconId: 2460 },
    { id: 14, displayName: 'Valakus King of Fire', name: 'Valakus', iconId: 2461 },
    { id: 15, displayName: 'Fire God Grenigas', name: 'Grenigas', iconId: 2462 },
    { id: 16, displayName: 'Lord Draco', name: 'LordDraco', iconId: 2547 },
    { id: 17, displayName: 'Glacerus the Ice Cold', name: 'Glacerus', iconId: 2583 },
    { id: 18, displayName: 'Foxy', name: 'Foxy', iconId: 2662 },
    { id: 19, displayName: 'Maru the Milennial Tiger', name: 'Maru', iconId: 2674 },
    { id: 20, displayName: 'Witch Laurena', name: 'Laurena',  iconId: 2698 },
    { id: 22, displayName: 'Lola Lopears', name: 'LolaLopears',  iconId: 2716 },
    { id: 23, displayName: 'Goddess Zenas', name: 'Zenas', iconId: 2750 },
    { id: 24, displayName: 'Goddess Erenia', name: 'Erenia', iconId: 2751 },
    { id: 25, displayName: 'Incomplete Fernon', name: 'Fernon', iconId: 2878 },
    { id: 26, displayName: 'Greedy Fafnir', name: 'Fafnir', iconId: 2905 },
    { id: 29, displayName: 'Mad March Hare', name: 'MadMarchHare', iconId: 4121 },
    { id: 30, displayName: 'Spirit King Kirollas', name: 'Kirollas', iconId: 4271 },
    { id: 31, displayName: 'Beast King Carno', name: 'Carno', iconId: 4272 },
    { id: 32, displayName: 'Demon God Belial', name: 'Belial', iconId: 4273 },
    { id: 33, displayName: 'Evil Overlord Paimon', name: 'Paimon', iconId: 4304 },
    { id: 35, displayName: 'Zombie Dragon Valehir', name: 'Valehir', iconId: 4612 },
    { id: 36, displayName: 'Ice Dragon Alzanor', name: 'Alzanor', iconId: 4615 },
    { id: 37, displayName: 'Most Giant Pollutus', name: 'Pollutus', iconId: 7094 },
    { id: 38, displayName: 'Giant Arma', name: 'GiantArma', iconId: 7095 },
    { id: 39, displayName: 'Ultimate Giant Arma', name: 'UltimateGiantArma', iconId: 7135 },
  ]
  currentPage = 1
  perPage = 10
  totalCount = 0

  @Watch('activeRaid')
  async onActiveRaidChange(newRaid) {
    this.currentRaidData = this.data.find(e => e.raidType === newRaid.name)

    const raidTypeData = await Game.getRaidRecords(newRaid.id)

    this.raidTypeData = raidTypeData
    this.totalCount = this.raidTypeData.length
    this.updateRankingData(1)
  }

  get characterNames() {
    return this.characters.map(e => e.name)
  }

  updateRankingData(value) {
    this.currentPage = value
    const min = this.currentPage === 1 ? 0 : (this.currentPage - 1) * this.perPage
    const max = this.currentPage ===  1 ? this.perPage : (this.currentPage - 1) * this.perPage + this.perPage

    this.raidTypeDataFiltered = this.raidTypeData.slice(min, max)
  }

  getClassAvatarId(character): number {
    let sexes: Nullable<{ male: number, female: number }> = null

    switch (character.classType) {
      case 'Swordman':
        sexes = { male: 32040, female: 32060 }
        break
      case 'Archer':
        sexes = { male: 32080, female: 32100 }
        break
      case 'Magician':
        sexes = { male: 32120, female: 32140 }
        break
      default:
        sexes = { male: 32000, female: 32020 }
    }

    return character.genderType === 'Male' ? sexes.male : sexes.female
  }

  setActiveRaid(raid) {
    this.activeRaid = this.raids.find(e => e.id === raid.id)
  }

  formatPlayerName(player) {
    return `${player.name} Lv.${player.level} (+${player.heroLevel})`
  }

  parseRaidTime(raidTime: string) {
    const [time, ms] = raidTime.split('.')
    const [hours, minutes, seconds] = time.split(':')
    let finalRaidTime = ''

    if (+hours > 0) finalRaidTime += `${Number(hours)}h`
    if (+minutes > 0) finalRaidTime += `${Number(minutes)}m `
    if (+seconds > 0) finalRaidTime += `${Number(seconds)}s`

    finalRaidTime += ` ${ms.slice(0, 3)}ms`

    return finalRaidTime
  }
}
